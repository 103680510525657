body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: "white";
}

/* Title for Cover Page Oswald - Capital Letters */

@font-face {
  font-family: "OswaldBold";
  src: url("./assets/fonts/oswald/Oswald-Bold.ttf");
}

/* Big Headlines and Title Oswald - Capital Letters */

@font-face {
  font-family: "OswaldDemiBold";
  src: url("./assets/fonts/oswald/Oswald-DemiBold.ttf");
}

/* Headlines Oswald - Capital Letters */

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/oswald/Oswald-Regular.ttf");
}

/* Subtitles */

@font-face {
  font-family: "RobotoBoldCondensed";
  src: url("./assets/fonts/roboto/Roboto-BoldCondensed.ttf");
}

/* Footer */

@font-face {
  font-family: "RobotoCondensed";
  src: url("./assets/fonts/roboto/Roboto-Condensed.ttf");
}

/* Text Body */

@font-face {
  font-family: "RobotoRegular";
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf");
}

/* Quotes */

@font-face {
  font-family: "RobotoRegularItalic";
  src: url("./assets/fonts/roboto/Roboto-Italic.ttf");
}

/* Menu Items */

@font-face {
  font-family: "RobotoMedium";
  src: url("./assets/fonts/roboto/Roboto-Medium.ttf");
}

/* Menu Animation */

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}




.DateRangePickerInput__withBorder {
  height: 35px;
    overflow: hidden;
}

.DateInput__small{
  height: 35px;
  width: 105px !important;
}

.DateInput_input__small {
  font-size:1em;
  font-family: RobotoRegular;
  color: rgb(66, 66, 66);
}
@media screen and (max-width: 1280px) {
  .welcomeMessage {
   display: none;
  }

  .cLogoArea {
    width: 120px !important;
    overflow: hidden i !important;
  }

  .subHeaderRight {
    display: flex;
    align-items: flex-end;
    flex-direction: row !important; 
    width: 100%;
    width: calc(100% - 120px) !important;
    margin-right: 0 !important;
    justify-content: "space-between";
  }
  .alignTopQ{
    margin-bottom: 22px  !important;
  }
}

svg {
  overflow: initial !important;
}



.DateRangePickerInput_calendarIcon {
  height: 32px !important;
  margin-bottom: 3px;
}
/*
.makeStyles-dateIcon-79 {
  margin-top: -4px !important; 
}
*/
.DateRangePickerInput__withBorder {
  border-radius: 0px !important;
}
.DayPickerNavigation {
  display: flex !important;
  flex-direction: row !important; 
  justify-content: space-between !important;
}
.DateInput_input__small {
  text-align: center !important;
}
.DateInput_input__focused{
  border-bottom: 1px solid #C43042 !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #c43042;
  border: 1px double #c43042;
  color: #fff;
}
.CalendarDay__selected_span {
  background: rgba(196, 48, 66,0.1);
  border: 1px double  rgba(196, 48, 66,0.1);
  color: #d24659;
}
.CalendarDay__hovered_span {
  background:  rgba(196, 48, 66,0.1);
  border: 1px double  rgba(196, 48, 66,0.1);
  color: #d24659;
}

.CalendarDay__hovered_span:hover {
  background: #c43042;
  border: 1px double #c43042;
  color: #fff;
}
.dateIcon{
  height: 15px;
  width: 15px
}
.subHeaderRight {
  display: flex;
  align-items: center;
  width: "auto";
  margin-right: 0 !important;

}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background: #c43042;
  border: 1px double #c43042;
  color: #fff;
}

::-webkit-scrollbar {-webkit-appearance: none;}
::-webkit-scrollbar {
  width: 10px;
} 
::-webkit-scrollbar-track {
  background: #EFEFEF; 
} 
::-webkit-scrollbar-thumb {
  background: #C43042;
  max-height: 1px ;
  border-radius:5px;
} 
::-webkit-scrollbar-thumb:hover {
  background: #333333; 
}	