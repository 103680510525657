.slider-container {
  position: relative;
  overflow: hidden;
  width: 30vw;
}

.slider-item {
  width: 30vw;
  position: relative;
  height: 100px;
}

.slider-dot {
  font-size: 1em;
  cursor: pointer;
  user-select: none;
  color: white;
}

.slider-dots {
  text-align: center;
  width: 30vw;
  z-index: 100;
}

.yellow-dot {
  width: 90%;
}

.square-big {
  width: 3vw;
}

.square-small {
  width: 1.5vw;
}
.animation-login {
  animation-name: circleAnimationTop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: absolute;
}

@keyframes circleAnimationTop {
  0% {
    right: 10%;
    top: 10%;
  }
  25% {
    right: 15%;
    top: 10%;
  }
  50% {
    right: 15%;
    top: 13%;
  }
  75% {
    right: 10%;
    top: 13%;
  }
  100% {
    right: 10%;
    top: 10%;
  }
}

.animation2-login {
  animation-name: circleAnimationBottom;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: absolute;
}

@keyframes circleAnimationBottom {
  0% {
    left: 10%;
    bottom: 5%;
  }
  25% {
    left: 12%;
    bottom: 4%;
  }
  50% {
    left: 12%;
    bottom: 5%;
  }
  75% {
    left: 10%;
    bottom: 4%;
  }
  100% {
    left: 10%;
    bottom: 5%;
  }
}
.animation3-login {
  animation-name: circleAnimationBall;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  position: absolute;
  opacity: 0.5;
}

@keyframes circleAnimationBall {
  0% {
    left: 15%;
    top: 5%;
  }
  25% {
    left: 17%;
    top: 4%;
  }
  50% {
    left: 17%;
    top: 5%;
  }
  75% {
    left: 15%;
    top: 4%;
  }
  100% {
    left: 15%;
    top: 5%;
  }
}
@media only screen and (max-width: 1280px) {
  .slider-container {
    width: 50vw;
  }

  .slider-item {
    width: 50vw;
  }
}

@media only screen and (max-width: 940px) {
  .slider-container {
    width: 70vw;
  }

  .slider-item {
    width: 70vw;
  }

  .yellow-dot {
    width: 70%;
  }

  .square-big {
    width: 35px;
  }

  .square-small {
    width: 20px;
  }
}
